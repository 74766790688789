import { devtools } from 'zustand/middleware';
import create from 'zustand';
import produce from 'immer';

const useStore = create(
  devtools((set) => ({
    steps: [],
    currentStep: null,
    currentStepNumber: 0,
    setNextStep: () =>
      set(
        produce((state) => {
          state.currentStepNumber += 1;
          state.currentStep = state.steps[state.currentStepNumber];
        }),
      ),
    prevStep: () =>
      set(
        produce((state) => {
          state.currentStepNumber -= 1;
          state.currentStep = state.steps[state.currentStepNumber];
        }),
      ),
    setSteps: (steps) => {
      set(
        produce((state) => {
          state.steps = steps;
        }),
      );
    },
    setCurrentStep: (step) => {
      set(
        produce((state) => {
          state.currentStep = step;
        }),
      );
    },
  })),
);

export default useStore;
