import fieldTypes from './fieldTypes';

import countryCodes from './CampaignStepFormTel/countryCodes';

const getDynamicInitialValues = (fields, stepId) => {
  const initialValues = {
    [stepId]: {},
  };

  fields.forEach(({ fieldId, type }) => {
    const isTel = type === fieldTypes.TEL;

    if (isTel) {
      initialValues[stepId][fieldId] = {
        countryCode: countryCodes[0].value,
        phoneNumber: '',
      };
    } else {
      initialValues[stepId][fieldId] = '';
    }
  });

  return initialValues;
};

export default getDynamicInitialValues;
