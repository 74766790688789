/* eslint-disable arrow-body-style */
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import Campaign from '../../pages/Campaign';
import VenueFinder from '../../pages/VenueFinder';

import '../../styles/index.output.css';

const App = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-XRB1JCHT75" />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}');

          `}
        </script>
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/:campaignPath" element={<Campaign />} />
          <Route path="/:campaignPath/venues" element={<VenueFinder />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </HelmetProvider>
  );
};

export default App;
