const temporaryTransformPayload = (payload) => {
  const answer =
    payload['10d06dd2-de54-4f11-8c28-4ca5d0a93168'][
      '32106dd2-de54-4f11-8c28-4ca5d0a93168'
    ].toUpperCase();
  const age =
    payload['6a76dd94-7994-474f-a2e7-aaa09003ff20']['321x6pd2-de54-4f11-8c28-4ca5d0a93168'];
  const detailsStep = payload['10d06dd2-da51-4f11-8c28-4ca5d0a93168'];
  const postcode = detailsStep['xoa06dd2-de54-4f11-8c28-4ca5d0a93168'];
  const email = detailsStep['gsa06dd2-de54-4f11-8c28-4ca5d0a93168'];
  const { countryCode, phoneNumber } = detailsStep['53606dd2-de54-4f11-8c28-4ca5d0a93168'];
  const sanitisedPhoneNumber = phoneNumber.replace(new RegExp(/^0/), '');

  return {
    postcode,
    email,
    phone: `${countryCode}${sanitisedPhoneNumber}`,
    answer,
    age,
  };
};

export default temporaryTransformPayload;
