import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect } from 'react';

import useCampaignStore from '../../store/campaign';
import useStepsStore from '../../store/steps';
import useFormStore from '../../store/form';
import useSignupStore from '../../store/signup';

import LoadingSpinner from '../../components/LoadingSpinner';

import CampaignStep from './CampaignStep';

import temporaryTransformPayload from '../../api/temporaryTransformPayload';

import useQuery from '../../hooks/useQuery';

const Campaign = () => {
  const { campaignPath } = useParams();
  const {
    data: campaignData,
    loading: campaignLoading,
    error: campaignError,
    fetchData: fetchCampaign,
  } = useCampaignStore();
  const { setSteps, steps, setNextStep, setCurrentStep, currentStep, currentStepNumber } =
    useStepsStore();
  const { setStepFields, stepsFields } = useFormStore();
  const { postData: postSignup } = useSignupStore();
  const {
    steps: campaignSteps,
    campaignId,
    campaignColor,
    ...restOfCampaignData
  } = campaignData || {};
  const isFinalStep = currentStepNumber === steps.length - 1;
  const originQuery = useQuery().get('origin') || '';
  const redirectUrl = originQuery ? decodeURIComponent(originQuery) : '';
  const hasSteps = !!steps.length;

  if (campaignColor) {
    document.documentElement.style.setProperty('--campaign-color', campaignColor);
  }

  const handlePostSignup = useCallback(async () => {
    await postSignup({
      path: campaignId,
      payload: temporaryTransformPayload(stepsFields),
    });
  }, [campaignId, postSignup, stepsFields]);

  useEffect(() => {
    fetchCampaign(campaignPath);
  }, [campaignPath, fetchCampaign]);

  useEffect(() => {
    if (campaignSteps) {
      setSteps(campaignSteps);
    }
  }, [campaignSteps, setSteps]);

  useEffect(() => {
    if (hasSteps) {
      const [firstStep] = steps;

      setCurrentStep(firstStep);
    }
  }, [hasSteps, setCurrentStep, steps]);

  return (
    <div className="campaign">
      {campaignLoading && <LoadingSpinner />}
      {campaignError && <p>Error loading campaign</p>}
      {currentStep && (
        <div className="campaign-steps">
          <CampaignStep
            step={currentStep}
            setNextStep={setNextStep}
            setStepFields={setStepFields}
            handlePostSignup={handlePostSignup}
            isFinalStep={isFinalStep}
            redirectUrl={redirectUrl}
            campaignData={{ campaignId, ...restOfCampaignData }}
          />
        </div>
      )}
    </div>
  );
};

export default Campaign;
