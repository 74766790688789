import { useParams, useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect } from 'react';

import useCampaignStore from '../../store/campaign';
import useVenueStore from '../../store/venues';
import LoadingSpinner from '../../components/LoadingSpinner';
import VenuesSearch from './VenuesSearch';

const VenueFinder = () => {
  const { campaignPath } = useParams();
  const navigate = useNavigate();
  const {
    data: campaignData,
    loading: campaignLoading,
    error: campaignError,
    fetchData: fetchCampaign,
  } = useCampaignStore();
  const {
    data: venuesData,
    loading: venuesLoading,
    error: venuesError,
    fetchData: fetchVenues,
  } = useVenueStore();
  const {
    campaignId,
    campaignColor,
    venuesSearch: { buttonText, headerImageUrl, subtitle, title } = {},
  } = campaignData || {};

  if (campaignColor) {
    document.documentElement.style.setProperty('--campaign-color', campaignColor);
  }

  const gotoCampaign = () => {
    navigate(`/${campaignPath}`);
    window.location.pathname = `/${campaignPath}`;
  };

  useEffect(() => {
    fetchCampaign(campaignPath);
  }, [campaignPath, fetchCampaign]);

  useEffect(() => {
    if (campaignId) fetchVenues(campaignId);
  }, [fetchVenues, campaignId]);

  const searchlocation = useCallback(
    ({ latitude, longitude }) => {
      fetchVenues(campaignId, { latitude, longitude });
    },
    [campaignId, fetchVenues],
  );

  const styles = {
    backgroundImage: `url("${headerImageUrl}")`,
  };

  return (
    <div className="campaign campaign-venues">
      {campaignLoading && <LoadingSpinner />}
      {campaignError && <p>Error loading campaign</p>}
      {campaignData && (
        <div className="campaign-steps">
          <div className="campaign-step">
            <div className="campaign-venues__banner" style={{ ...styles }}>
              <div
                className="campaign-venues__banner__title"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: `${title}${subtitle}` }}
              />
            </div>
            {venuesLoading && <LoadingSpinner />}
            {venuesError && <p>Error loading venues</p>}
            {venuesData && (
              <VenuesSearch
                venuesData={venuesData}
                campaignData={campaignData}
                searchlocation={searchlocation}
                campaignColor={campaignColor}
              />
            )}
            <div className="campaign-venues__content">
              <div className="campaign-venues__content__wrapper">
                <button
                  className="campaign-venues__content__btn"
                  type="button"
                  onClick={gotoCampaign}
                >
                  {buttonText}
                </button>
                <p className="campaign-venues__content__helper-text">Takes less than a minute</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VenueFinder;
