import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';

import CampaignStepFormRadio from './CampaignStepFormRadio';
import CampaignStepFormRegular from './CampaignStepFormRegular';
import CampaignStepFormTel from './CampaignStepFormTel';

import getDynamicInitialValues from './getDynamicInitialValues';
import getDynamicValidationSchema from './getDynamicValidationSchema';

import fieldTypes from './fieldTypes';
import FormikListener from '../../../../components/FormikListener';

const CampaignStepForm = ({ step, setNextStep, setStepFields, handlePostSignup, campaignData }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const { question, form, stepId, isSubmit, footerImageUrl, title } = step;
  const [finalInitialValues, setFinalInitialValues] = useState({});
  const { termsAndConditionsUrl, privacyUrl } = campaignData || {};
  const finalValidationSchema = useMemo(
    () => getDynamicValidationSchema(form, stepId),
    [form, stepId],
  );

  useEffect(() => {
    setFinalInitialValues(getDynamicInitialValues(form, stepId));
  }, [form, stepId]);

  const handleOnChange = useCallback(
    (values) => {
      setStepFields({ values });
    },
    [setStepFields],
  );

  const handleOnSubmit = async () => {
    try {
      setErrorMessage(null);
      await handlePostSignup();

      setNextStep();
    } catch (error) {
      setErrorMessage(error);
    }
  };

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage.message, {
        position: 'top-center',
        autoClose: 6000,
        hideProgressBar: true,
      });
    }
  }, [errorMessage]);

  const handleNextStep = () => {
    setNextStep();
  };

  /* eslint-disable react/no-danger */
  return (
    <div className="campaign-step__form">
      {title && (
        <div className="campaign-step__form__title" dangerouslySetInnerHTML={{ __html: title }} />
      )}
      <p className="campaign-step__form__question">{question}</p>
      <Formik
        initialValues={finalInitialValues}
        onSubmit={handleOnSubmit}
        enableReinitialize
        validationSchema={finalValidationSchema}
        isInitialValid={false}
      >
        {({ errors, touched, values, isValid, isSubmitting }) => (
          <Form>
            <>
              <FormikListener values={values} callback={handleOnChange} />
              {form.map((field) => {
                const { type, fieldId } = field;
                const isRadio = type === fieldTypes.RADIO;
                const isRegular = [fieldTypes.TEXT, fieldTypes.NUMBER, fieldTypes.EMAIL].includes(
                  type,
                );
                const isTel = type === fieldTypes.TEL;

                let DynamicFormStep = null;

                switch (true) {
                  case isRadio:
                    DynamicFormStep = CampaignStepFormRadio;
                    break;
                  case isRegular:
                    DynamicFormStep = CampaignStepFormRegular;
                    break;
                  case isTel:
                    DynamicFormStep = CampaignStepFormTel;
                    break;
                  default:
                    return null;
                }

                return (
                  <DynamicFormStep
                    field={field}
                    stepId={stepId}
                    key={fieldId}
                    error={
                      touched?.[stepId]?.[fieldId] && errors?.[stepId]?.[fieldId]
                        ? errors?.[stepId]?.[fieldId]
                        : null
                    }
                  />
                );
              })}
              {isSubmit && (
                <button
                  className="campaign-step__form__button"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                >
                  {errorMessage ? 'Retry' : 'Submit'}
                </button>
              )}
              {!isSubmit && (
                <button
                  className="campaign-step__form__button"
                  type="button"
                  disabled={!isValid}
                  onClick={() => handleNextStep(values)}
                >
                  Continue
                </button>
              )}
            </>
          </Form>
        )}
      </Formik>
      <footer className="campaign-step__form__footer">
        {termsAndConditionsUrl && privacyUrl && isSubmit && (
          <div className="campaign-step__form__footer__terms">
            By clicking &apos;SUBMIT&apos;, you agree to the{' '}
            <a href={privacyUrl} rel="noreferrer" target="_blank">
              Privacy Policy
            </a>{' '}
            and{' '}
            <a href={termsAndConditionsUrl} rel="noreferrer" target="_blank">
              Terms & Conditions{' '}
            </a>
            England & Wales residents aged 18+ only.
          </div>
        )}
        {footerImageUrl && (
          <img className="campaign-step__form__footer__img" src={footerImageUrl} alt="" />
        )}
      </footer>
    </div>
  );
};

export default CampaignStepForm;
