/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Field } from 'formik';
import clsx from 'clsx';

const CampaignStepFormRadio = ({ field, stepId }) => {
  const [selected, setSelected] = useState(null);
  const { answers, fieldId } = field;
  const isCombo = answers.length > 2;
  const classes = clsx('campaign-step__form__radio', {
    'campaign-step__form__radio--combo': isCombo,
  });

  const handleSelected = (index) => {
    setSelected(index);
  };

  return (
    <fieldset className={classes}>
      {answers.map(({ value, text, imageUrl }, index) => {
        const answerClasses = clsx('campaign-step__form__radio__answer', {
          'campaign-step__form__radio__answer--selected': selected === index,
        });

        return (
          <div
            key={value}
            className={answerClasses}
            role="button"
            tabIndex={0}
            onKeyDown={() => handleSelected(index)}
            onClick={() => handleSelected(index)}
          >
            <div className="campaign-step__form__radio__answer__content">
              {!isCombo && (
                <img
                  className="campaign-step__form__radio__answer__content__img"
                  alt={text}
                  src={imageUrl}
                />
              )}
              <label htmlFor={value}>
                <span className="campaign-step__form__radio__answer__content__text">{text}</span>
                <Field type="radio" id={value} name={`${stepId}.${fieldId}`} value={value} />
              </label>
            </div>
          </div>
        );
      })}
    </fieldset>
  );
};

export default CampaignStepFormRadio;
