/* eslint-disable max-len */
import React, { useEffect, useRef } from 'react';

const CampaignBackground = ({ videoUrl, imageUrl }) => {
  const videoRef = useRef();

  useEffect(() => {
    if (videoUrl) {
      videoRef.current.load();
    }
  }, [videoUrl, imageUrl]);

  return videoUrl ? (
    <div className="campaign-step__background-video">
      <video
        disableRemotePlayback
        muted
        playsInline
        preload="metadata"
        loop
        autoPlay
        poster={imageUrl}
        ref={videoRef}
      >
        <source src={videoUrl} type="video/mp4" />
        Sorry, your browser doesn&apos;t support video.
      </video>
    </div>
  ) : (
    <div className="campaign-step__background-image">
      <img src={imageUrl} alt="product background" />
    </div>
  );
};

export default CampaignBackground;
