/* eslint-disable jsx-a11y/label-has-associated-control */
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Fuse from 'fuse.js';

import GoogleMapReact from 'google-map-react';
// import InfoWindow from 'react-google-maps/lib/components/InfoWindow';
import axios from 'axios';
import { ReactComponent as Point } from '../../assets/images/venues/point.svg';
import { ReactComponent as Reset } from '../../assets/images/venues/reset.svg';

const defaultProps = {
  center: { lat: 54.613, lng: -4.251 },
  zoom: 5,
};

const Marker = ({ children }) => <div className="venues-search__marker">{children}</div>;

const MarkerLabel = ({ name, postcode }) => (
  <div className="venues-search__marker-label">
    <p>
      <strong>{name}</strong>
    </p>
    <a
      href={`https://www.google.com/maps/search/?api=1&query=${name},${postcode}`}
      target="_blank"
      rel="noreferrer"
    >
      Get directions
    </a>
  </div>
);

const Field = ({ field, reference, onChange, onReset }) => {
  const { label, fieldId, placeholder, helperText } = field;
  const classes = clsx('campaign-step__form__regular', {
    'campaign-step__form__regular--has-helper-text': helperText,
  });

  return (
    <div className={classes}>
      {label && <label htmlFor={fieldId}>{label}</label>}
      <input
        id={fieldId}
        type="text"
        placeholder={placeholder}
        ref={reference}
        onChange={onChange}
      />
      {helperText && <div className="campaign-step__form__regular__helper-text">{helperText}</div>}

      <button className="venues-search__reset-button" type="button" onClick={onReset}>
        <Reset />
      </button>
    </div>
  );
};

const VenuesSearch = ({ venuesData, searchlocation, campaignData }) => {
  const { venuesSearch: { description, descriptionIcon, mapMarkerIcon, mapStylePath } = {} } =
    campaignData || {};

  venuesData?.sort((a, b) => a.distance?.distance - b.distance?.distance);
  const inputRef = useRef();
  const [filteredVenues, setFilteredVenues] = useState(venuesData);
  const [showMarkerLabel, setShowMarkerLabel] = useState(null);
  const [numberOfResults, setNumberOfResults] = useState(10);
  const [mapStyleJson, setMapStyleJson] = useState(null);
  const [showList, setShowList] = useState(true);
  const hasNoMoreResults = numberOfResults >= filteredVenues?.length;

  useEffect(() => {
    const getJson = async () => {
      const { data } = await axios.get(mapStylePath);
      setMapStyleJson(data);
    };
    getJson();
  }, [mapStylePath]);

  useEffect(() => {
    setFilteredVenues(venuesData);
  }, [venuesData]);

  const increaseResults = useCallback(() => {
    setNumberOfResults(numberOfResults + 10);
  }, [numberOfResults]);

  const fuse = useMemo(() => {
    const fuseInstance = new Fuse(venuesData, {
      keys: ['address', 'name', 'postcode', 'city'],
      threshold: 0.4,
      minMatchCharLength: 3,
    });
    return fuseInstance;
  }, [venuesData]);

  const searchVenues = useCallback(
    (event) => {
      event?.preventDefault();
      const { value: searchTerm } = inputRef.current;
      if (searchTerm.length < 3) {
        setFilteredVenues(venuesData);
        return;
      }
      const results = fuse.search(searchTerm);
      const newFiltered = results.reduce((a, c) => [...a, c.item], []);
      setFilteredVenues(newFiltered);
    },
    [fuse, venuesData],
  );

  const tabChange = (event) => {
    setShowList(event.target.dataset.choice === 'list');
  };

  const onMarkerClick = (key) => {
    setShowMarkerLabel(key);
  };

  const sortByLocation = useCallback(() => {
    const success = (pos) => {
      const { coords } = pos;
      searchlocation(coords);
    };

    const error = (err) => {
      // eslint-disable-next-line no-console
      console.warn(`ERROR(${err.code}): ${err.message}`);
    };
    navigator.geolocation.getCurrentPosition(success, error);
  }, [searchlocation]);

  const reset = useCallback(() => {
    inputRef.current.value = '';
    searchVenues();
  }, [searchVenues]);

  return (
    <>
      <div className="venues-search">
        <div className="venues-search__card">
          <button
            type="button"
            className="venues-search__link venue-search__location"
            onClick={sortByLocation}
          >
            <Point className="venues-search__point" />
            <strong>Search using my current location</strong>
          </button>
          <p>
            <strong>Search</strong>
          </p>
          <p>Town, City, Venue</p>
          <Field
            field={{ fieldId: 'searchTerm', placeholder: 'Search' }}
            reference={inputRef}
            onChange={searchVenues}
            onReset={reset}
          />
        </div>
      </div>
      <div className="venues-search__tabs" onChange={(e) => tabChange(e)}>
        <input
          className="radio-list"
          id="list"
          name="tabs"
          type="radio"
          data-choice="list"
          checked={showList}
          readOnly
        />
        <label htmlFor="list">List</label>
        <input
          className="radio-map"
          id="map"
          name="tabs"
          type="radio"
          data-choice="map"
          checked={!showList}
          readOnly
        />
        <label htmlFor="map">Map</label>
      </div>
      <div className="venues-search__list" hidden={!showList}>
        {filteredVenues.length === 0 ? (
          <div className="venues-search__list__empty">
            Sorry there are no results for this search
          </div>
        ) : (
          filteredVenues
            .slice(0, numberOfResults)
            .map(({ name, address, postcode, distanceText }) => (
              <div key={`${name}-${postcode}`} className="venues-search-result">
                <p>
                  <strong>{name}</strong>
                </p>
                <p className="venues-search-result__address">{`${address} ${postcode}`}</p>
                <div className="venues-search-result__footer">
                  <a
                    className="venues-search__link"
                    target="_blank"
                    href={`https://www.google.com/maps/search/?api=1&query=${postcode}`}
                    rel="noreferrer"
                  >
                    Get directions
                  </a>
                  {distanceText && <div>{distanceText}</div>}
                </div>
              </div>
            ))
        )}
      </div>
      {!hasNoMoreResults && (
        <button type="button" className="venues-search__more" onClick={increaseResults}>
          <strong>MORE RESULTS</strong>
        </button>
      )}
      <div
        hidden={showList}
        className="venues-search__map"
        style={{ position: 'relative', width: '100%', height: 0, paddingBottom: '100%' }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          onClick={() => setShowMarkerLabel(null)}
          onChildClick={onMarkerClick}
          style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
          options={{ styles: mapStyleJson }}
        >
          {filteredVenues.map(({ lat, lon, name, postcode }) =>
            lat ? (
              <Marker key={`${name}-${postcode}`} lat={lat} lng={lon} name={name}>
                <img src={mapMarkerIcon} alt="Marker" />
                {showMarkerLabel === `${name}-${postcode}` && (
                  <MarkerLabel name={name} postcode={postcode} />
                )}
              </Marker>
            ) : null,
          )}
        </GoogleMapReact>
      </div>
      <div className="venue-search__message">
        {descriptionIcon && <img src={descriptionIcon} alt="icon" />}
        <p>{description}</p>
      </div>
    </>
  );
};

export default VenuesSearch;
