import React from 'react';
import { Field } from 'formik';
import clsx from 'clsx';

import countryCodes from './countryCodes';

const CampaignStepFormTel = ({ field, stepId }) => {
  const { label, fieldId, placeholder, type, helperText } = field;
  const parsedType = type.toLowerCase();
  const classes = clsx('campaign-step__form__tel', {
    'campaign-step__form__tel--has-helper-text': helperText,
  });

  return (
    <div className={classes}>
      {label && <label htmlFor={fieldId}>{label}</label>}
      <div className="campaign-step__form__tel__wrapper">
        <Field as="select" name={`${stepId}.${fieldId}.countryCode`} id={fieldId}>
          {countryCodes.map(({ label: countryLabel, value }) => (
            <option key={countryLabel} value={value}>
              {countryLabel}
            </option>
          ))}
        </Field>
        <Field
          id={fieldId}
          name={`${stepId}.${fieldId}.phoneNumber`}
          type={parsedType}
          placeholder={placeholder}
        />
      </div>
      {helperText && <div className="campaign-step__form__tel__helper-text">{helperText}</div>}
    </div>
  );
};

export default CampaignStepFormTel;
