import React from 'react';
import { Field } from 'formik';
import clsx from 'clsx';

const CampaignStepFormRegular = ({ field, stepId, error }) => {
  const { label, fieldId, placeholder, type, helperText } = field;
  const parsedType = type.toLowerCase();
  const classes = clsx('campaign-step__form__regular', {
    'campaign-step__form__regular--error': error,
    'campaign-step__form__regular--has-helper-text': helperText,
  });

  return (
    <div className={classes}>
      {label && <label htmlFor={fieldId}>{label}</label>}
      <Field
        id={fieldId}
        name={`${stepId}.${fieldId}`}
        type={parsedType}
        placeholder={placeholder}
      />
      {helperText && <div className="campaign-step__form__regular__helper-text">{helperText}</div>}
    </div>
  );
};

export default CampaignStepFormRegular;
