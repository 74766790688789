import { devtools } from 'zustand/middleware';
import create from 'zustand';
import axios from 'axios';

const createAsyncNetworkStore = (set, endpoint) => ({
  loading: false,
  error: null,
  data: null,
  fetchData: async (path, params) => {
    set((state) => ({ ...state, loading: true }));
    try {
      const queryparams = params ? `?${new URLSearchParams(params).toString()}` : '';
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/${endpoint}/${path}${queryparams}`,
      );

      set((state) => ({ ...state, loading: false, data }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      set((state) => ({ ...state, loading: false, error: true }));
    }
  },
  postData: async ({ path, payload }) => {
    set((state) => ({ ...state, loading: true }));

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/${endpoint}/${path}`,
        payload,
      );

      set((state) => ({ ...state, loading: false, data, error: false }));
    } catch (error) {
      set((state) => ({ ...state, loading: false, error }));

      throw new Error(error?.response?.data?.message || 'Something went wrong');
    }
  },
});

const createUseStore = (endpoint) =>
  create(devtools((set) => createAsyncNetworkStore(set, endpoint)));

export default createUseStore;
