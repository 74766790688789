import { useEffect } from 'react';

const FormikListener = ({ values, callback }) => {
  useEffect(() => {
    callback(values);
  }, [callback, values]);

  return null;
};

export default FormikListener;
