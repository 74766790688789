import React from 'react';

import CampaignBackground from './CampaignBackground';
import CampaignStepForm from './CampaignStepForm';
import CampaignStepText from './CampaignStepText';

import stepTypes from './stepTypes';

const CampaignStep = ({
  step,
  setNextStep,
  setStepFields,
  handlePostSignup,
  isFinalStep,
  redirectUrl,
  campaignData,
}) => {
  const { type, videoUrl, imageUrl } = step;

  let DynamicStep = null;

  switch (type) {
    case stepTypes.TEXT:
      DynamicStep = CampaignStepText;
      break;
    case stepTypes.FORM:
      DynamicStep = CampaignStepForm;
      break;
    default:
      return null;
  }

  return (
    <div className="campaign-step">
      <CampaignBackground videoUrl={videoUrl} imageUrl={imageUrl} />
      <DynamicStep
        step={step}
        setNextStep={setNextStep}
        setStepFields={setStepFields}
        handlePostSignup={handlePostSignup}
        isFinalStep={isFinalStep}
        redirectUrl={redirectUrl}
        campaignData={campaignData}
      />
    </div>
  );
};

export default CampaignStep;
