import { devtools } from 'zustand/middleware';
import create from 'zustand';
import produce from 'immer';

const useStore = create(
  devtools((set) => ({
    stepsFields: {},
    setStepFields: (payload) =>
      set(
        produce((state) => {
          const { values } = payload;

          state.stepsFields = { ...state.stepsFields, ...values };
        }),
      ),
  })),
);

export default useStore;
