/* eslint-disable no-unused-vars */
import React from 'react';
// import { Link } from 'react-router-dom';

// import CampaignStepRedirectButton from './CampaignStepRedirectButton';
// import CampaignStepShareButton from './CampaignStepShareButton';

const CampaignStepText = ({ step, isFinalStep, redirectUrl, campaignData }) => {
  const { title, subTitle, share, footerText } = step;
  const { hasShareBtn, title: shareTitle, text: shareText } = share || {};
  const { hasVenuesMap } = campaignData || {};
  const isRedirectButtonVisible = redirectUrl && isFinalStep;

  return (
    <div className="campaign-step__text">
      {/* TODO: either abstract or delete */}
      <img
        alt=""
        className="temp__campaign-step__text__logo"
        // eslint-disable-next-line max-len
        src="https://assets.dev.sessions.systems/brandup/campaigns/1146dd94-7994-474f-a2e7-aaa09003ff2c/corona_logo_white.png"
      />
      <p className="campaign-step__text__title">{title}</p>
      <p className="campaign-step__text__sub-title">{subTitle}</p>
      {/* TODO: delete if not needed */}
      {/* <div className="campaign-step__button-links">
        {isRedirectButtonVisible && <CampaignStepRedirectButton redirectUrl={redirectUrl} />}
        {hasVenuesMap && (
          <Link to="venues" className="campaign-step__button-link">
            FIND VENUES OFFERING YOUR FREE GUINNESS
          </Link>
        )}
        {hasShareBtn && <CampaignStepShareButton title={shareTitle} text={shareText} />}
      </div> */}
      {footerText && <p className="campaign-step__text__footer-text">{footerText}</p>}
    </div>
  );
};

export default CampaignStepText;
