import * as Yup from 'yup';

import fieldValidationTypes from './fieldValidationTypes';

const getDynamicValidationSchema = (fields, stepId) => {
  const validationSchema = {};

  fields.forEach(({ fieldId, validation, required }) => {
    const isPostCode = validation === fieldValidationTypes.POSTCODE;
    const isEmail = validation === fieldValidationTypes.EMAIL;
    const isPhone = validation === fieldValidationTypes.TEL;

    switch (true) {
      case isPostCode:
        validationSchema[fieldId] = Yup.string().min(4).max(8).required(required);
        break;
      case isPhone:
        validationSchema[fieldId] = Yup.object()
          .shape({
            countryCode: Yup.string(),
            phoneNumber: Yup.string().min(5),
          })
          .required(required);
        break;
      case isEmail:
        validationSchema[fieldId] = Yup.string().email().required(required);
        break;
      default:
        validationSchema[fieldId] = Yup.string().required(required);
        break;
    }
  });

  return Yup.object().shape({
    [stepId]: Yup.object().shape(validationSchema),
  });
};

export default getDynamicValidationSchema;
